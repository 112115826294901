<template>
    <div class="p-2">
        <div
            class="data-list-item add"
            @click.stop="$emit('add')"
        >
            <div class="item-img-cont add-title">
                <i class="fa fa-plus"/>
            </div>
            <div class="item-text-cont">
            <span class="item-title-add">
              {{ $t('common.add_new') }}
            </span>
                <font-awesome-icon
                    icon="eye"
                    class="card-body-icon"
                />
            </div>
        </div>
        <div v-if="results && results.length" class="col-sm-12 nopads">
            <transition name="fade">
                <div
                    class="col-sm-12 pb-2 result-table"
                >
                    <b-table
                        :small="true"
                        :responsive="true"
                        :items="results"
                        :fields="fields"
                        :bordered="true"
                        hover
                        class="pointer"
                        @row-clicked="onRowClicked"
                    >
                        <template slot="empty">
                            <div class="text-center my-2">
                                {{ $t('area_report.no_results') }}
                            </div>
                        </template>
                        <template v-slot:cell(group)="row">
                                {{ row.item.observation_group.name }}
                        </template>
                        <template v-slot:cell(geomType)="row">
                            {{ row.item.geometry_type === 1 ? $t('observations.point') : $t('observations.line')}}
                        </template>
                    </b-table>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ObservationTypeList',
    props: {
        results: {
            type: Array,
            default: function () {
                return []
            }
        }
    },
    data() {
        return {
            fields: [
                {key: 'id', label: this.$t('observations.id'), sortable: true},
                {key: 'name', label: this.$t('observations.type'), sortable: true},
                {key: 'group', label: this.$t('observations.group'), sortable: false},
                {key: 'geomType', label: this.$t('observations.geometry_type'), sortable: false}
            ]
        }
    },
    methods: {
        onRowClicked(item) {
            this.$emit('edit', item)
        }
    }
}
</script>
